<template>
    <layout>
        <div class="w-100">
            <div class="row justify-content-center">
                <div class="col-10 col-sm-7 col-md-5 col-lg-8 col-xl-7 col-xxl-6 login-wrapper py-5">
                    <div class="mx-auto login-w-limit">
                        <div class="logo-wrapper text-center mb-2">
                            <img src="@/assets/img/bau-logo-for-light.png" height="35"/>
                        </div>
                        <h4 class="text-center mb-5">
                            {{ $t('undergraduate_transfer') }} {{ $t('application_system') }}
                        </h4>
                        <div class="fadeIn">
                            <ValidationObserver ref="form">
                                <div class="mx-auto">
                                    <ValidationProvider name="username" rules="required" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('national_id')+' / '+$t('passport_number')">
                                            <b-form-input type="text"
                                                          v-model="loginForm.username"
                                                          :state="errors[0] ? false : null"/>
                                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                    <ValidationProvider name="pin" rules="required" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('pin')">
                                            <password-input v-model="loginForm.pin" :validateError="errors[0]"/>
                                        </b-form-group>
                                    </ValidationProvider>
                                    <div class="d-flex justify-content-center mb-4">
                                        <b-button variant="primary" block @click="login()">
                                            {{ $t('login').toUpper() }}
                                        </b-button>
                                    </div>
                                    <a class="small text-center d-block text-muted cursor-pointer"
                                       @click="goToRegister()">
                                        İlk Şifreni Al
                                    </a>
                                </div>
                            </ValidationObserver>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </layout>
</template>

<script>
    // Layout
    import Layout from '@/modules/undergraduateTransfer/layout/Layout'

    // Component
    import PasswordInput from '@/components/elements/PasswordInput'

    // Service
    import UndergraduateTransferService from '@/services/UndergraduateTransferService'

    // Other
    import {ValidationObserver, ValidationProvider} from 'vee-validate'
    import Base from '@/plugins/Base'

    export default {
        components: {
            PasswordInput,
            Layout,

            ValidationObserver,
            ValidationProvider
        },
        data() {
            return {
                loginForm: {
                    username: null,
                    pin: null
                }
            }
        },
        mounted() {
            this.$store.dispatch('undergraduateTransfer/isLoginGoIndex');
        },
        methods: {
            async login() {
                const isValid = await this.$refs.form.validate();
                if (isValid) {
                    UndergraduateTransferService.login(this.loginForm)
                                                .then(response => {
                                                    let data = response.data.data;

                                                    // Data
                                                    if (data) {
                                                        this.$store.commit('undergraduateTransfer/setData', data)
                                                        Base.LocalStorage.set('undergraduate_transfer_data', data)
                                                    }

                                                    // Username
                                                    let username = data.undergraduate_transfer.national_id ?
                                                        data.undergraduate_transfer.national_id :
                                                        data.undergraduate_transfer.passport_number;
                                                    if (username) {
                                                        this.$store.commit('undergraduateTransfer/setUsername', username)
                                                        Base.LocalStorage.set('undergraduate_transfer_username', username)
                                                    }

                                                    // Pin
                                                    if (data.undergraduate_transfer.pin) {
                                                        this.$store.commit('undergraduateTransfer/setPin', data.undergraduate_transfer.pin)
                                                        Base.LocalStorage.set('undergraduate_transfer_pin', data.undergraduate_transfer.pin)
                                                    }
                                                })
                                                .then(() => {
                                                    this.$router.push('/transfer');
                                                })
                                                .catch((e) => {
                                                    this.showErrors(e);
                                                })
                }
            },
            goToRegister() {
                this.$router.push('/transfer/register');
            }
        }
    }
</script>
